<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <div style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
        
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:28px !important;`">
            <v-col
            cols="12"
            md="10"
            sm="12">
            <form autocomplete="off">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>اختر {{lang.start_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>اختر {{lang.end_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-input-group>
                        <b-form-input class="inborder" v-model="mobile" style="background:#efefef;" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.plate_number}}</label>
                        <b-input-group>     
                        <b-form-input class="inborder" v-model="plate_number" style="background:#efefef;" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group>
                        <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
                        </b-input-group>
                    </v-col>
                </v-row>
            </form>
            <b-input-group class="mt-3" style="display:none;">
                <b-form-input placeholder="Start Date" onfocus="(this.type='date')" style="border:1px solid #000;border-radius: 0;direction:rtl" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" onfocus="(this.type='date')" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                <b-form-input :placeholder="lang.mobile"  style="border:1px solid #000;" v-model="mobile"></b-form-input>
                <b-form-input :placeholder="lang.plate_number" style="border:1px solid #000;" v-model="plate_number"></b-form-input>
                <b-input-group-append style="border:1px solid #000">
                <b-button variant="light"
                style="background: gold !important;font-size:14px;color:#000;border:1px solid #ccc;" @click="getCurrentCards()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>

            <v-col cols="12" class="mt-2" style="display:none;">
                <h6 class="h6title backBlack text-center">{{lang.maintenance_cards}}</h6>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{lang.cardid}}</th>
                            <th class="text-center backBlack">{{lang.plate_number}}</th>
                            <th class="text-center backBlack">{{lang.car_model}}</th>
                            <th class="text-center backBlack">{{lang.customer_name}}</th>
                            <th class="text-center backBlack">{{lang.company_name}}</th>
                            <th class="text-center backBlack">{{lang.mobile}}</th>
                            <th class="text-center backBlack">{{lang.received}}</th>
                            <th class="text-center backBlack">{{lang.delivered}}</th>
                            <th class="text-center backBlack">{{lang.spare_partes}}</th>
                            <th class="text-center backGreen">{{lang.total}}</th>
                            <th class="text-center backRed">{{lang.vat}}</th>
                            <th class="text-center backBlack">{{lang.paid}}</th>
                            <th class="text-center backBlack">{{lang.remain}}</th>
                            <th class="text-center backBlack">{{lang.action}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tablerows" :key="index">
                            <td style="width:100px">{{ item.cardid }}</td>
                            <td>{{ item.plate_number }}</td>
                            <td>{{ item.car_model }}</td>
                            <td nowrap>{{ item.customer }}</td>
                            <td nowrap>{{ item.company_name }}{{ item.entity_name }}</td>
                            <td>{{ item.mobile }}</td>
                            <td nowrap>{{ item.date_in }}</td> 
                            <td nowrap>{{ item.date_out }}</td>
                            <td>{{ item.parts }}</td>
                            <td class="backGreen">{{ item.ftotal }}</td>
                            <td class="backRed">{{ item.vat }}</td>
                            <td>{{ item.paid }}</td>
                            <td>{{ item.remaining }}</td>
                            <td style="width:50px;background:black;border-bottom:2px solid #fff !important;">
                                <v-btn style="width:50px;font-size:0.2em;background:black;box-shadow:none;color:#fff;height:25px !important;" v-b-toggle.view_card @click="ViewItem(item)">{{lang.view}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="12">
                <h6 class="h6title backBlack text-center"  style="display:none;">{{lang.invoices}}</h6>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{lang.invoiceno}}</th>
                            <th class="text-center backBlack">{{lang.customer_type}}</th>
                            <th class="text-center backBlack">{{lang.customer}}</th>
                            <th class="text-center backBlack">{{lang.company_name}}</th>
                            <th class="text-center backBlack">{{lang.mobile}}</th>
                            <th class="text-center backBlack">{{lang.inv_date}}</th>
                            <!-- <th class="text-center backBlack">{{lang.model}}</th>
                            <th class="text-center backBlack">{{lang.inv_date}}</th> -->
                            <th class="text-center backGreen">{{lang.total}}</th>
                            <th class="text-center backRed">{{lang.vat}}</th>
                            <th class="text-center backBlack">{{lang.ftotal}}</th>
                            <th class="text-center backBlack">{{lang.paid}}</th>
                            <th class="text-center backBlack">{{lang.remain}}</th>
                            <th class="text-center backBlack">{{lang.action}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in invoicesrows" :key="index">
                            <td style="width:100px;text-align:center;">{{ item.invoice_number }}</td>
                            <td :style="`border:2px solid #fff !important;width:50px;text-align:center;` + item._customer_type_style">{{ item._customer_type }}</td>
                            <td style="text-align:center;" nowrap>{{ item.full_name }}</td>
                            <td style="text-align:center;" nowrap>{{ item.company_name }}{{ item.entity_name }}</td>
                            <td style="text-align:center;">{{ item.mobile }}</td>
                            <!-- <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ item.plate_number }}</td>
                            <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts">{{ item.model }}</td> -->
                            <td style="text-align:center;" nowrap>{{ item.inv_date.substr(0,10) }}</td>  
                            <td style="text-align:center;background:lightgreen">{{ item.total }}</td>
                            <td style="text-align:center;background:lightgreen">{{ item.vat }}</td>
                            <td style="text-align:center;">{{ item.ftotal }}</td>
                            <td style="text-align:center;">{{ item.paid }}</td>
                            <td style="text-align:center;">{{ item.remain }}</td>
                            <td style="text-align:center;background:#000;border-bottom:2px solid #FFF !important;width:50px;">
                            <v-btn style="font-size:0.2em;background:#000;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(item)">{{lang.view}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    <Footer />
    <view-card ref="viewCard" />
    <popViewInvocie ref="popviewinv" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ViewCard from '@/components/view-card.vue'
import PopViewInvocie from '@/components/popViewInvocie.vue';
import ExportsPrint from '@/components/exports-print.vue'
import axios from 'axios'
export default {
  components: { breadCrumbs,TabsComp, Footer,HeaderPortrate,ViewCard,PopViewInvocie,ExportsPrint },
  data() {
    return {
          tablerows:[
          ],
          invoicesrows:[],
        //   sdate: '',
        //   edate: '',
          plate_number: '',
          mobile: '',
          sd: {
            day: 1,
            month: (new Date()).getMonth() + 1,
            year: (new Date()).getFullYear(),
          },
          ed: {
            day: (new Date()).getDate() + 1,
            month: (new Date()).getMonth() + 1,
            year: (new Date()).getFullYear(),
          }
      }
  },
  methods: {
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
      ViewItem(item){
        this.$refs.viewCard.cardid = item.cardid;
        this.$refs.viewCard.card = item;
        this.$refs.viewCard.showButtons = false;
        this.$refs.viewCard.updateChileds();
        this.$refs.viewCard.calckInv();
      },
      getInvo(item){
          this.$refs.popviewinv.invid = item.id;
          this.$refs.popviewinv.invoice = item;
          this.$refs.popviewinv.showButtons = false;
          this.$refs.popviewinv.getInvoice();
      },
      getCurrentInvoice(){
        
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        let post = new FormData();
        post.append('type','getAllInvoices');
        post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        post.append('auth',cook);
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
          invoice_number: this.invoice_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',data.plate_number);
        this.invoicesrows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
           // // console.log("invoices",res);
          if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
              && res.error.number != null && res.error.number == 200){
                this.invoicesrows = res.results.data;
              }
        })
      },
      getCurrentCards(){
        if(this.plate_number == '' && this.mobile == ''){
            return false
        }
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        post.append('type','getCardslist'); 
        post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',this.plate_number);
        this.tablerows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          // // console.log("corot",res);
          this.tablerows = res.results.data;
          this.getCurrentInvoice();
        })
      },
  },
  computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        items: function(){
            return  {
                text: this.lang.customers,
                disabled: true,
                href: '/clientList',
            };
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
  },
  created(){
    this.getStartDate();
  }
}
</script>